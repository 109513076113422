require("preact/devtools");

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, set, onValue, serverTimestamp, off, orderByChild, query, get } from "firebase/database";
import { getAuth, signInAnonymously, onAuthStateChanged, RecaptchaVerifier } from "firebase/auth";
import { useState, useEffect } from "preact/hooks";
import React from "react";
import Webcam from "react-webcam";
import Router from "preact-router";
import { QRCode } from "@jackybaby/react-custom-qrcode";
import ReactModal from "react-modal";
import OtpInput from 'react-otp-input';

import "./style";
import "./background";
import "./honeycomb";

import cameraIcon from './assets/camera.png'

const firebaseConfig = {
  apiKey: "AIzaSyDvyoxMFanr3IOm40GNVktNn7KSfs0vKaI",
  authDomain: "roskilde2023.firebaseapp.com",
  databaseURL: "https://roskilde2023-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "roskilde2023",
  storageBucket: "roskilde2023.appspot.com",
  messagingSenderId: "369624479193",
  appId: "1:369624479193:web:04c563fd9a3e114f10d5c8",
  measurementId: "G-3M9JD4EZ9Q"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getDatabase(app);
const auth = getAuth();

let actions = [];
onValue(ref(db, `actions`), (snapshot) => {
  const data = snapshot.val();
  actions = Object.keys(data).map(k => ({title: k, action: data[k]}));
  console.log(actions);
});

let allFriends = [];
let noPhone = [];

// onValue(ref(db, `users`), snapshot => {
//   const users = snapshot.val();
//   let us = [];
//   for(const key of Object.keys(users)) {
//     const u = users[key];
//     if(!u.phone && (u.createdAt || u.created)) noPhone.push(u.createdAt || u.created);

//     if(!u.phone || !u.createdAt || u.phone.startsWith("+372")) continue;

//     //await db.ref(`/users/${uid}/completionMessageSent`).set(true);
//     //await db.ref(`/users/${uid}/bingoCompleted`).set(true);
//     us.push({...u,
//       uid: key,
//       when: new Date(u.createdAt).toLocaleString(),
//       count: Object.keys(u.friends ?? {}).length
//     });
//     allFriends.push(...(Object.keys(u.friends ?? {}).map(k => ({...u.friends[k], uid: k}))));
//   }

//   allFriends = allFriends.filter((u, index) => {
//     return allFriends.findIndex(x => x.image == u.image) !== index;
//   });

//   console.log(us.sort((a, b) => b.createdAt - a.createdAt));
//   console.log(noPhone.sort((a, b) => b - a).map(c => new Date(c).toLocaleString()));
//   console.log(allFriends);
// });

//connectDatabaseEmulator(db, "192.168.88.189", 4000);

function Qr({uid}) {
  return (
    <QRCode value={`https://${location.host}/${uid}`} bgColor="#4bff0a" eyeRadius={[
      [10, 10, 0, 10],
      [10, 10, 10, 0],
      [10, 0, 10, 10],
    ]}/>
  );
}

function FriendList({uid}) {
  const [friends, setFriends] = useState([]);
  const [modal, setModal] = useState({isOpen: false, img: null});

  useEffect(() => {
    const u = ref(db, `users/${uid}/friends`);
    onValue(u, (snapshot) => {
      const data = snapshot.val() ?? {};

      setFriends(Object.keys(data).map(k => ({
        uid: k,
        image: data[k].image,
        prompt: data[k].prompt,
        created: data[k].created
      })).sort((a, b) => b.created - a.created));
    });

    return () => off(u, "value");
  }, [uid]);

  return (
    <>
    <div class="main">
      <div class="container honeycomb-container">
        {[false, ...Array.from({...friends, length: 3}), false].map((friend) => (
          <div onClick={() => friend && setModal({isOpen: friend?.image != null, friend: friend})} style={{
            backgroundImage: friend?.image ? `url(${friend?.image})` : "",
            backgroundPosition: friend?.image ? "center" : "",
            backgroundSize: friend?.image ? "cover" : "",
            backgroundRepeat: friend?.image ? "no-repeat" : ""
          }}>
          {(friend?.image != null || friend === false) && (<p></p>)}
          </div>
        ))}
      </div>
    </div>
    <ReactModal isOpen={modal.isOpen} ariaHideApp={false} onRequestClose={() => setModal({isOpen: false, img: null})}>
      <div class="modal-content"
        onClick={() => setModal({isOpen: false, img: null})} style="height: 100%">

        <img src={modal.friend?.image} class="img"/>

        <h3>{new Date(modal.friend?.created ?? 0).toLocaleString()}</h3>

        <h2>{modal.friend?.prompt.title}</h2>

        <p>{modal.friend?.prompt.action}</p>
      </div>
    </ReactModal>
    </>
  );
}

function Countdown({ seconds, onFinished }) {
  const [timeLeft, setTimeLeft] = useState(seconds);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((t) => {
        if(t < 1) onFinished();
        return t - 1;
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return <div class="countdown">{timeLeft}</div>;
}

function PhoneNumberEntry({ uid, verified }) {
  console.log("PhoneNumberEntry");

  const [state, setState] = useState({
    phoneNumber: null,
    error: null
  });

  return (<div class="form-main">
    <h1>{`enter your phone number to start the game >>>`}</h1>
    <input class="input shadow" type="tel" placeholder="+4412345678" onChange={e => setState({...state, phoneNumber: e.target.value})} />
    <button class="btn shadow" onClick={() => {
      if(!state.phoneNumber) {
        setState({...state, error: true})
        return;
      }

      set(ref(db, `users/${uid}/phone`), state.phoneNumber);  
    }}>
      create temporary player
    </button>
    {state.error && <h4 class="error">enter your phone number to continue</h4>}
  </div>);
}

function Game({friend}) {
  const [uid, setUid] = useState(null);
  const [isFriended, setIsFriended] = useState(null);
  const [hasPhone, setHasPhone] = useState(null);
  const [showTutorial, setShowTutorial] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [gameClosed, setGameClosed] = useState(null);
  const [bingoCompleted, setBingoCompleted] = useState(null);
  const [bingoCompletedModelOpen, setBingoCompletedModelOpen] = useState(false);
  const [nextAction, setNextAction] = useState(null);

  useEffect(() => {
    const u = ref(db, `gameClosed`);

    const sub = onValue(u, (snapshot) => {
      setGameClosed(snapshot.val());
    });

    //return () => ref(db, `users/${uid}/friends`).off(sub);
  }, []);

  useEffect(() => {
    signInAnonymously(auth).then(() => {
      console.log("signed in...");
    }).catch((error) => {
      console.error(error);
    });

    onAuthStateChanged(auth, (user) => {
      if(user) {
        setUid(user.uid);
        console.log(user.uid);
      } else {
        console.log("signed out...")
      }
    });
  }, []);

  useEffect(() => {
    if(!uid) return;

    const u = ref(db, `users/${uid}/phone`);

    const sub = onValue(u, (snapshot) => {
      console.log("phone", snapshot.val());
      setHasPhone(snapshot.exists());
    });

    onValue(ref(db, `users/${uid}/showTutorial`), snapshot => {
      setShowTutorial(snapshot.val() ?? true);
      setModalOpen(snapshot.val() ?? true);
    });

    onValue(ref(db, `users/${uid}/bingoCompleted`), snapshot => {
      setBingoCompleted(snapshot.val() ?? false);
      setBingoCompletedModelOpen(snapshot.val() ?? false);
    });

    onValue(ref(db, `users/${uid}/showBingoCompleted`), snapshot => {
      if(snapshot.val() === false) setBingoCompletedModelOpen(false);
    });

    onValue(ref(db, `users/${uid}/nextAction`), snapshot => {
      setNextAction(snapshot.val() ?? actions[actions.length * Math.random() << 0]);
    });

    //return () => ref(db, `users/${uid}/friends`).off(sub);
  }, [uid]);

  if(friend) useEffect(() => {
    const u = ref(db, `users/${friend}/friends/${uid}/created`);

    const sub = onValue(u, (snapshot) => {
      console.log("isFriended", snapshot.val());
      setIsFriended(snapshot.exists());
    });

    //return () => ref(db, `users/${uid}/friends`).off(sub);
  }, [uid]);

  if(!uid || (friend && isFriended == null) || hasPhone == null) return (
    <div id="app" class="loader"></div>
  );

  if(gameClosed) return (
    <iframe
      title="Closed for the day"
      width="100%"
      height="100%"
      src="https://www.youtube.com/embed/kQxatiQZ1xw?cc_load_policy=1&cc_lang_pref=en"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen></iframe>
  );

  if(isFriended === false) return (
    <>
      <Capture uid={uid} friend={friend}/>
      <div class="spacer"></div>
      <div class="terms">
        <a href="/tos.html">Terms of service</a>
        <a href="/privacy-policy.html">Privacy policy</a>
      </div>
    </>
  );


  if(hasPhone == null && friendData.isFriended === true && showTutorial == null) return (
    <div id="app" class="loader"></div>
  );

  if(!hasPhone) return (
    <>
      <PhoneNumberEntry uid={uid}/>

      <div class="terms">
        <a href="/tos.html">Terms of service</a>
        <a href="/privacy-policy.html">Privacy policy</a>
      </div>
    </>
  );

  return (
    <>
      <div class="spacer"></div>
      <Qr uid={uid}/>
      {bingoCompleted === true && <div class="bingo-done" onClick={() => setBingoCompletedModelOpen(true)}>
          <p>Bio-Bingo Completed! ⓘ</p>
      </div>}

      {!bingoCompleted && <div class="next-action">
        <h2>{nextAction?.title}</h2>
        <p>{nextAction?.action}</p>
      </div>}

      <FriendList uid={uid}/>


      <ReactModal isOpen={modalOpen} ariaHideApp={false}>
        {/* <p>Play video for transmission:</p> */}
        <iframe title="Your Mission" class="iframe-video" width="100%" height="auto" src="https://www.youtube.com/embed/582zlQF02GA?cc_load_policy=1&cc_lang_pref=en" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen />

        <div class="spacer"></div>
        <h2 style="margin-top: 0.4em">Instructions:</h2>

        <ol style="text-align: left; margin: 0 auto;">
          <p><li>Spot a stranger.</li></p>
          <p><li>Engage using your Bio-Bingo task.</li></p>
          <p><li>Have them scan your QR code for an invite.</li></p>
          <p><li>Follow instructions and do interaction together.</li></p>
          <p><li>Snap a selfie together.</li></p>
          <p><li>Repeat until card's full.</li></p>
        </ol>
        <div class="spacer"></div>
        <button class="btn" onClick={() => {
          set(ref(db, `users/${uid}/showTutorial`), false);
          setModalOpen(false);
        }}>Let's Bingo!</button>
      </ReactModal>

      <ReactModal class="react-modal" isOpen={bingoCompletedModelOpen} ariaHideApp={false}>

        <iframe class="iframe-video"
          title="Completion"
          width="100%" height="auto" src="https://www.youtube.com/embed/gAegQ5zdEn8?cc_load_policy=1&cc_lang_pref=en" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen />

        <p>You're invited to exclusive Bio Diversity lunch at Roskilde! Limited seats, first-come, first-serve for 25 seats. Food provided, event times are:<br/>
Lunch 1: <b>13-13:30</b><br/>
Lunch 2: <b>13:30-14:00</b><br/>
Arrive 10 mins early to guarantee seats! Coordinates:</p>

        <iframe class="iframe-map" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2253.261748193013!2d12.0872778!3d55.614861100000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNTXCsDM2JzUzLjUiTiAxMsKwMDUnMTQuMiJF!5e0!3m2!1sda!2see!4v1687740292513!5m2!1sda!2see" width="100%" height="auto" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />

        <button class="btn" onClick={() => {
          set(ref(db, `users/${uid}/showBingoCompleted`), false);
          setBingoCompletedModelOpen(false);
        }}>Dismiss!</button>
      </ReactModal>


      <div class="terms">
        <a href="/tos.html">Terms of service</a>
        <a href="/privacy-policy.html">Privacy policy</a>
      </div>
    </>
  );
}

const videoConstraints = {
  width: 512,
  height: 512,
  facingMode: "user"
};

function Capture({uid, friend}) {
  const [isRunning, setIsRunning] = useState(false);
  const [isTakingPhoto, setIsTakingPhoto] = useState(false);
  const [action, setAction] = useState(null);
  const cam = React.useRef(null);

  const capture = React.useCallback(
    async () => {
      console.log("click");

      if(!isRunning) {
        setIsRunning(true);
        setIsTakingPhoto(true);
      }
    },
    [ref]
  );

  if(friend) useEffect(() => {
    onValue(ref(db, `users/${friend}/nextAction`), snapshot => {
      setAction(snapshot.val() ?? actions[actions.length * Math.random() << 0]);
    });
  }, [uid]);

  return (
    <>
    <div class="next-action">
      <h1>{action?.title}</h1>
      <p>{action?.action}</p>
    </div>

    <div class="capture">
      <Webcam
        audio={false}
        ref={cam}
        screenshotFormat="image/webp"
        videoConstraints={videoConstraints}
      />
      {isRunning && (<Countdown seconds={3} onFinished={() => {
        console.log("onFinished", friend, uid, action);

        if(friend && friend != uid) {
          const img = cam.current.getScreenshot();
          console.log(img)
          set(ref(db, `users/${uid}/friends/${friend}/created`), serverTimestamp());
          set(ref(db, `users/${uid}/friends/${friend}/image`), img);
          set(ref(db, `users/${uid}/friends/${friend}/prompt`), action);

          set(ref(db, `users/${friend}/friends/${uid}/created`), serverTimestamp());
          set(ref(db, `users/${friend}/friends/${uid}/image`), img);
          set(ref(db, `users/${friend}/friends/${uid}/prompt`), action);

          const availableActions = actions.filter(v => v.title != action.title);
          set(ref(db, `users/${friend}/nextAction`), availableActions[availableActions.length * Math.random() << 0])
        }
        setIsRunning(false)
      }}/>)}
      {!isRunning && <div class="trigger"></div>}
      {!isTakingPhoto && <img src={cameraIcon} alt="Take photo" onClick={capture} />}
    </div>
    </>
  );
};

export default function App() {
  return (
    <>
      <Router>
        <Game path=":friend?"/>
      </Router>
      <div id="RecaptchaVerifier">
      </div>
      <div class="background">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </>
  );
}
